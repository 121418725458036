import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';

import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ConnectionAgentService } from '@cue/admin-core';
import { CONSTANTS } from '@cue/admin-constants';
import { FormErrorHandler } from '../../handlers';

@Component({
  selector: 'app-connection-agent-device-code-flow',
  templateUrl: './connection-agent-device-code-flow.component.html',
  styleUrls: ['./connection-agent-device-code-flow.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, ButtonModule, CommonModule, MatIconModule],
})
export class ConnectionAgentDeviceCodeFlowComponent
  extends FormErrorHandler
  implements OnInit, OnDestroy
{
  public parentForm: FormGroup;

  public pairClicked$ = new BehaviorSubject<boolean>(false);
  public deviceCodeTokenResponse$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public deviceCodeResponse$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public counter$: BehaviorSubject<number> = new BehaviorSubject<any>(null);
  private count: number;
  private timer: any;
  private countdownTimer: any;

  @Input() connectionAgentType: number;
  @Output() tokenData = new EventEmitter<{
    accessToken: string;
    refreshToken: string;
    expiresInUTC: string;
  }>();

  @Input() anonymousAccess = false;

  // parent form controls names
  @Input() usernameFormControl = 'username';
  @Input() tenantIdFormControl = 'tenantId';
  @Input() clientIdFormControl = 'clientId';
  @Input() useDeviceCodeFlowDropdownFormControl = 'useDeviceCodeFlow';
  @Input() synchronizeFromGroups = false;

  constructor(
    public controlContainer: ControlContainer,
    private changeDetectorRef: ChangeDetectorRef,
    private connectionAgentService: ConnectionAgentService,
  ) {
    super(controlContainer);
  }

  ngOnInit(): void {
    this.parentForm = this.controlContainer.control as FormGroup;
  }

  ngOnDestroy() {
    this.connectionAgentService.cancelDeviceCodeFlow(this.anonymousAccess).subscribe();
  }

  pairClick() {
    if (
      this.parentForm.get(this.tenantIdFormControl).invalid ||
      this.parentForm.get(this.clientIdFormControl).invalid ||
      this.parentForm.get(this.usernameFormControl).invalid
    ) {
      this.parentForm.get(this.tenantIdFormControl).markAsTouched();
      this.parentForm.get(this.tenantIdFormControl).markAsDirty();
      this.parentForm.get(this.clientIdFormControl).markAsTouched();
      this.parentForm.get(this.clientIdFormControl).markAsDirty();
      this.parentForm.get(this.usernameFormControl).markAsTouched();
      this.parentForm.get(this.usernameFormControl).markAsDirty();
      this.changeDetectorRef.detectChanges();
    } else {
      this.getUserCode();
    }
  }

  getScope() {
    switch (this.connectionAgentType) {
      case CONSTANTS.code.conAgntApiOperations: {
        return 'calendars.readWrite calendars.readWrite.shared user.read';
      }
      case CONSTANTS.code.conAgntUnits: {
        return 'user.read calendars.readWrite.shared mail.send';
      }
      case CONSTANTS.code.conAgntSendingEmails: {
        return 'user.read mail.send';
      }
      case CONSTANTS.code.conAgntImportUsers: {
        return this.synchronizeFromGroups
          ? 'user.read user.readBasic.all place.read.all calendars.read.shared groupMember.read.all group.read.all'
          : 'user.read user.readBasic.all place.read.all calendars.read.shared';
      }
    }
  }

  getUserCode() {
    this.deviceCodeResponse$.next(null);
    this.deviceCodeTokenResponse$.next(null);

    const payload = {
      tenantId: this.parentForm.get(this.tenantIdFormControl).value,
      clientId: this.parentForm.get(this.clientIdFormControl).value,
      scope: this.getScope(), // 'user.read calendars.readWrite calendars.readWrite.shared'
    };

    this.pairClicked$.next(true);
    this.parentForm.get(this.useDeviceCodeFlowDropdownFormControl).disable();
    this.connectionAgentService.deviceCode(payload, this.anonymousAccess).subscribe((response) => {
      if (!response.success) {
        this.deviceCodeResponse$.next(response);
        this.pairClicked$.next(false);
        this.parentForm.get(this.useDeviceCodeFlowDropdownFormControl).enable();
      } else {
        this.deviceCodeResponse$.next(response);
        if (response.data.error) {
          this.parentForm.get(this.useDeviceCodeFlowDropdownFormControl).enable();
          this.pairClicked$.next(false);
          return;
        }
        this.count = response.data.expiresIn;
        this.countdownTimer = setInterval(() => {
          if (this.count <= 0) {
            clearInterval(this.countdownTimer);
          }
          this.counter$.next(--this.count);
        }, 1000);

        this.timer = setInterval(() => {
          return this.connectionAgentService
            .getDeviceCodeStatus(this.anonymousAccess)
            .subscribe((res) => {
              this.deviceCodeTokenResponse$.next(res);
              if (!res.success) {
                clearInterval(this.timer);
                this.pairClicked$.next(false);
                this.parentForm.get(this.useDeviceCodeFlowDropdownFormControl).enable();
              } else {
                if (!res.data.pendingAuthorization) {
                  clearInterval(this.timer);
                  this.pairClicked$.next(false);
                  this.parentForm.get(this.useDeviceCodeFlowDropdownFormControl).enable();

                  this.tokenData.emit({
                    accessToken: res.data.deviceCodeTokenResponse?.accessToken,
                    refreshToken: res.data.deviceCodeTokenResponse?.refreshToken,
                    expiresInUTC: res.data.deviceCodeTokenResponse?.expiresInUTC,
                  });
                }
              }
            });
        }, 6000);
      }
    });
  }

  cancelClicked() {
    this.connectionAgentService.cancelDeviceCodeFlow(this.anonymousAccess).subscribe();

    clearInterval(this.timer);
    this.pairClicked$.next(false);
    this.parentForm.get(this.useDeviceCodeFlowDropdownFormControl).enable();
    this.deviceCodeResponse$.next(null);
  }

  copyUserCode(span: HTMLSpanElement) {
    if ((document as any).selection) {
      const range = (document.body as any).createTextRange();
      range.moveToElementText(span);
      range.select();
    } else if (window.getSelection) {
      // others
      const range = document.createRange();
      range.selectNode(span);
      window.getSelection().empty();
      window.getSelection().addRange(range);
    }
    document.execCommand('copy');
  }
}

import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';
import { AppNotificationService } from './app-notification.service';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService implements ValidationService {
  public entity = CONSTANTS.entity.userGroup;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private appNotificationService: AppNotificationService,
  ) {}

  isUniqueName(name: string, id: string): Observable<any> {
    if (name) {
      const url = this.configService.value.apiURL + '/api/userGroup/uniqueName/' + name;
      return this.http.post(url, { id });
    }
    return of({ success: true });
  }

  add(payload: any): Observable<any> {
    return this.postAction(this.entity, payload);
  }

  edit(id: string, payload: any) {
    return this.postAction(this.entity + '/' + id + '/edit', payload);
  }

  delete(ids: string[]) {
    return this.postAction(this.entity + '/delete', ids);
  }

  getDetailById(id: string) {
    const filters = {
      take: 1,
      skip: 0,
      filter: {
        filters: [
          {
            field: 'id',
            value: id,
            operator: 'eq',
          },
        ],
        logic: 'and',
      },
    } as DataStateChangeEvent;

    const query = toDataSourceRequestString(filters);
    return this.http
      .post<any[]>(this.configService.value.apiURL + '/api/userGroup/grid?&' + query, {})
      .pipe(
        map(
          (resp: any) =>
            resp.data.map((userGroup) => ({
              ...userGroup,
            }))[0],
        ),
      );
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }
}

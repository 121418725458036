import { Injectable } from '@angular/core';

import { ApiConfig, Config } from '@cue/admin-shared';

// nedelat inRoot, je instancovano manualne kvuli bootstrapu!
@Injectable()
export class ConfigService {
  private _config: Config & ApiConfig;

  constructor() {}

  get value(): Config & ApiConfig {
    if (this._config) {
      return this._config;
    }
    return null;
  }

  load(): Promise<Config> {
    return new Promise((resolve, reject) => {
      const path = location.origin + '/config.json?d=' + new Date().toISOString();
      const configFileRequest = new XMLHttpRequest();
      configFileRequest.addEventListener('error', (e) => {
        reject('Cannot load config.json file.');
      });
      configFileRequest.addEventListener('load', (response) => {
        if (configFileRequest.status === 200) {
          try {
            const config = JSON.parse(configFileRequest.responseText) as Config;
            const configurationUrl = config.apiURL + '/api/admin/config';
            const serverConfigRequest = new XMLHttpRequest();
            serverConfigRequest.addEventListener('error', () => {
              reject('Cannot connect to API server, check if it is available');
            });
            serverConfigRequest.addEventListener('load', (serverResposne) => {
              if (serverConfigRequest.status === 200) {
                const serverConfig = JSON.parse(serverConfigRequest.responseText) as ApiConfig;
                this._config = { ...config, ...serverConfig };
                resolve(this._config);
              } else {
                console.error(
                  'Spatna konfigurace na serveru, /api/assist/configurations nevratil zadna data!',
                );
                reject('Cannot connect to API server, check if it is available');
              }
            });
            serverConfigRequest.open('GET', configurationUrl);
            serverConfigRequest.send();
          } catch (er) {
            reject(er);
          }
        } else {
          reject(configFileRequest.statusText);
        }
      });
      configFileRequest.open('GET', path);
      configFileRequest.send();
    });
  }
}

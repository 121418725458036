import { TranslateService } from '@ngx-translate/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';
import { AppNotificationService } from './app-notification.service';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { ValidationService } from './validation.service';

export interface LoadQrCodesForRequest {
  resourceName?: string;
  newsName?: string;
  kioskId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class QrCodeService implements ValidationService {
  public entity = CONSTANTS.entity.qrCode;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
    private appNotificationService: AppNotificationService,
  ) {}

  loadQrCodesForRequest(request: LoadQrCodesForRequest) {
    let dataRequest: DataSourceRequestState = null;
    if (request.resourceName == null && request.newsName == null && request.kioskId == null) {
      dataRequest = {
        sort: [
          {
            field: 'code',
            dir: 'desc',
          },
        ],
        filter: {
          filters: [
            {
              field: 'resourceName',
              operator: 'isnull',
            },
            {
              field: 'newsTitle',
              operator: 'isnull',
            },
            {
              field: 'kioskId',
              operator: 'isnull',
            },
          ],
          logic: 'and',
        },
      };
    } else if (request.kioskId) {
      dataRequest = {
        sort: [
          {
            field: 'code',
            dir: 'desc',
          },
        ],
        filter: {
          filters: [
            {
              field: 'kioskId',
              value: request.kioskId,
              operator: 'eq',
            },
          ],
          logic: 'and',
        },
      };
    } else {
      dataRequest = {
        sort: [
          {
            field: 'code',
            dir: 'desc',
          },
        ],
        filter: {
          filters: [
            {
              field: request.resourceName != null ? 'resourceName' : 'newsTitle',
              value: request.resourceName != null ? request.resourceName : request.newsName,
              operator: 'eq',
            },
          ],
          logic: 'and',
        },
      };
    }

    const requestString = toDataSourceRequestString(dataRequest);
    const url =
      `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/qrCode/grid?' + requestString;
    return this.http.post<any>(url, { showSelected: false, selection: [] });
  }

  loadData(): Observable<any> {
    const payload = {
      assistUrl: true,
    };
    return this.postAction(CONSTANTS.api.loadData, { data: payload });
  }

  loadDataForGrid(): Observable<any> {
    const payload = {
      qrCodeCategory: true,
      area: true,
    };
    return this.postAction(CONSTANTS.api.loadData, { data: payload });
  }

  getDetailById(id: string) {
    const filters = {
      take: 1,
      skip: 0,
      filter: {
        filters: [
          {
            field: 'id',
            value: id,
            operator: 'eq',
          },
        ],
        logic: 'and',
      },
    } as DataStateChangeEvent;

    const query = toDataSourceRequestString(filters);
    return this.http
      .post<any[]>(this.configService.value.apiURL + '/api/qrCode/grid?&' + query, {})
      .pipe(
        map(
          (resp: any) =>
            resp.data.map((qrCode) => ({
              ...qrCode,
            }))[0],
        ),
      );
  }

  getDropdownData() {
    return this.getAction(this.entity + '/dropdownData');
  }

  add(payload: any): Observable<any> {
    return this.postAction(this.entity, payload);
  }

  edit(id: string, payload: any) {
    return this.postAction(this.entity + '/' + id + '/edit', payload);
  }

  getSettings() {
    return this.getAction(this.entity + '/settings');
  }

  getResourceQrCodes(id: number) {
    return this.getAction(this.entity + '/resourceQrCodes/' + id);
  }

  getAllQrCodes(): Observable<unknown> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity;
    return this.http.get(url).pipe(
      map((r) => ({ data: r, state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  setSettings(payload: any) {
    return this.postAction(this.entity + '/settings', payload);
  }

  delete(ids: string[]) {
    return this.postAction(this.entity + '/delete', ids);
  }

  isUniqueName(name: string, id: string): Observable<any> {
    if (name) {
      const url = this.configService.value.apiURL + '/api/qrCode/uniqueName/' + name;
      return this.http.post(url, { id });
    }
    return of({ success: true });
  }

  multiEdit(payload): Observable<any> {
    const url = `${this.configService.value.apiURL}` + '/api/qrCode/multiEdit';
    return this.http.post(url, payload).pipe(
      map((r) => ({ state: 'success' })),
      catchError((errorData) =>
        of({
          state: 'failed',
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, payload).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({ success: false, errorCode: x.status, errorDescription: 'Unexpected error.' });
      }),
    );
  }
}

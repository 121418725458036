import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '@cue/admin-core';

@Injectable({
  providedIn: 'root',
})
export class UserValidators {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  createUniqueUserEmailValidator(id: string | number = 0): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (control.value == null) {
        return of(null);
      }

      const url = this.configService.value.apiURL + '/api/user/uniqueEmail/' + control.value;
      return this.http
        .post(url, { id })
        .pipe(map((response: any) => (response.success ? null : { valueIsUsed: true })));
    };
  }
}

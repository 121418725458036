<ng-container [formGroup]="parentForm">
    <h2>
        {{ 'connectionAgent.deviceCodeFlow' | translate }}
    </h2>
    <div class="row">
        <div class="col">
            <button
                kendoButton
                class="toolbarBtn"
                (click)="pairClick()"
                [disabled]="!parentForm.controls.useDeviceCodeFlow.value || (pairClicked$ | async)"
            >
                {{ 'connectionAgent.pair' | translate }}
            </button>
            <button
                kendoButton
                class="toolbarBtn"
                (click)="cancelClicked()"
                [disabled]="!(pairClicked$ | async)"
            >
                {{ 'actions.cancel' | translate }}
            </button>
        </div>
    </div>
    <ng-container *ngIf="parentForm.controls.useDeviceCodeFlow.value">
        <ng-container *ngIf="deviceCodeResponse$ | async as deviceCodeResponse">
            <ng-container *ngIf="deviceCodeResponse?.success">
                <ng-container *ngIf="deviceCodeResponse.data.error">
                    <div class="col">
                        <span class="item-error">
                            {{ deviceCodeResponse.data.error }}:
                            {{ deviceCodeResponse.data.errorDescription }}
                        </span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!deviceCodeResponse.data.error">
                    <div class="row">
                        <div class="col">
                            <div class="infoDiv">
                                <span class="infoLabel">
                                    {{ 'connectionAgent.completeSingIn' | translate }}
                                    <a
                                        href="https://microsoft.com/devicelogin"
                                        onclick="window.open('https://microsoft.com/devicelogin','popup','width=600,height=600'); return false;"
                                    >
                                        {{ 'connectionAgent.signIntoO365' | translate }}
                                    </a>
                                    {{ 'connectionAgent.useCode' | translate }}
                                </span>
                                <br />
                                <span class="infoValue">
                                    <span class="assist-callback-url"
                                        ><span #code>{{ deviceCodeResponse.data.userCode }}</span
                                        ><mat-icon
                                            (click)="copyUserCode(code)"
                                            svgIcon="icon_copy"
                                            class="icon"
                                        ></mat-icon
                                    ></span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="pairClicked$ | async">
                        <div class="col">
                            <div class="infoDiv">
                                <span class="infoLabel">{{
                                    'connectionAgent.codeExpiresIn' | translate
                                }}</span>
                                <br />
                                <span *ngIf="counter$ | async as counter">
                                    <span class="infoValue item-error" *ngIf="counter > 0">
                                        {{ counter * 1000 | date: 'mm:ss' }}
                                    </span>
                                    <span class="infoValue item-error" *ngIf="counter <= 0">
                                        00:00
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!deviceCodeResponse?.success">
                <div class="col">
                    <span class="item-error">
                        {{ deviceCodeResponse.errorCode }} {{ deviceCodeResponse.errorDescription }}
                    </span>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="deviceCodeTokenResponse$ | async as deviceCodeTokenResponse">
            <div
                class="row"
                *ngIf="
                    deviceCodeTokenResponse.success &&
                    !deviceCodeTokenResponse.data.pendingAuthorization
                "
            >
                <div class="col">
                    <ng-container
                        *ngIf="
                            deviceCodeTokenResponse.data.deviceCodeTokenResponse?.errorCodes
                                ?.length > 0;
                            else noError
                        "
                    >
                        <ng-container *ngIf="deviceCodeTokenResponse.data.expired">
                            <span class="item-error">{{
                                'connectionAgent.codeExpired' | translate
                            }}</span>
                        </ng-container>
                        <ng-container *ngIf="!deviceCodeTokenResponse.data.expired">
                            <span class="item-error">{{
                                'connectionAgent.DCActivationError' | translate
                            }}</span>
                            <span
                                *ngFor="
                                    let error of deviceCodeTokenResponse.data
                                        .deviceCodeTokenResponse?.errorCodes
                                "
                            >
                                {{ error }}
                                <ng-container [ngSwitch]="error">
                                    <ng-container *ngSwitchCase="70016">
                                        {{ 'connectionAgent.pendingAuth' | translate }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="7000218">
                                        {{ 'connectionAgent.publicFlows' | translate }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="70020">
                                        {{ 'connectionAgent.codeExpired' | translate }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="54005">
                                        {{ 'connectionAgent.authRedeemed' | translate }}
                                    </ng-container>
                                </ng-container>
                            </span>
                        </ng-container>
                    </ng-container>
                    <ng-template #noError>
                        <span class="item-success">{{
                            'connectionAgent.DCFActivated' | translate
                        }}</span>
                        <div class="item-info">
                            {{ 'connectionAgent.pressButtons' | translate }}.
                        </div>
                    </ng-template>
                </div>
            </div>
            <div
                class="row"
                *ngIf="
                    !deviceCodeTokenResponse?.success &&
                    !deviceCodeTokenResponse.data.pendingAuthorization
                "
            >
                <div class="col">
                    <span class="item-error">
                        {{ deviceCodeTokenResponse.errorCode }}
                        {{ deviceCodeTokenResponse.errorDescription }}
                    </span>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
} from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { retryWhen, mergeMap } from 'rxjs/operators';

export interface RetryParams {
  maxAttempts?: number;
  retry?: ({ status }) => boolean;
}

export const GENERATE_PIN = new HttpContextToken(() => false);

// Maximalni pocet pokusu je implicitne 5 + status 429. Tento retryInterceptor pujde ale pouzit klidne na odchyceni i ostatnich veci
const defaultRetrySettings: RetryParams = {
  maxAttempts: 5,
  retry: ({ status }) => status === 429,
};

export const retryStrategy =
  (params: RetryParams = {}) =>
  (attempts: Observable<any>) =>
    attempts.pipe(
      mergeMap((error, i) => {
        const { maxAttempts, retry } = { ...defaultRetrySettings, ...params };
        const retryAttempt = i + 1;
        if (retryAttempt > maxAttempts || !retry(error)) {
          return throwError(error);
        }
        return timer(error.error.retryAfter * 1000);
      }),
    );

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(GENERATE_PIN) === true) {
      return next.handle(req);
    }
    return next.handle(req).pipe(retryWhen(retryStrategy()));
  }
}

import { FileLibraryImage } from '@cue/admin-shared';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@cue/admin-core';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';

@Injectable({
  providedIn: 'root',
})
export class FileLibraryService {
  public entity = CONSTANTS.entity.file;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  getImages(): Observable<{
    data: FileLibraryImage[];
    success: boolean;
    errorCode: string;
    errorDescription: string;
  }> {
    const url =
      `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/images';
    return this.http.get<{
      data: FileLibraryImage[];
      success: boolean;
      errorCode: string;
      errorDescription: string;
    }>(url);
  }

  getImageForDownload(
    id: string,
  ): Observable<{ data: string; success: boolean; errorCode: string; errorDescription: string }> {
    const url =
      `${this.configService.value.apiURL}` +
      CONSTANTS.api.prefix +
      this.entity +
      '/imageForDownload/' +
      id;
    return this.http.get<{
      data: string;
      success: boolean;
      errorCode: string;
      errorDescription: string;
    }>(url);
  }

  imagesForPrint(
    ids: string[],
  ): Observable<{ data: any; success: boolean; errorCode: string; errorDescription: string }> {
    const url =
      `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/imagesForPrint';
    return this.http.post<{
      data: any;
      success: boolean;
      errorCode: string;
      errorDescription: string;
    }>(url, ids);
  }
}

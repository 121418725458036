import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';
import { SetRankService } from './set-rank.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceTypeService implements SetRankService {
  private entity = '/resourcetype';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  loadData(): Observable<any> {
    const payload = {
      resourceType: true,
      cueLicense: true,
      assistReservationDefaultTexts: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  loadDataForGrid(): Observable<any> {
    const payload = {
      cueLicense: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  getRank(): Observable<any> {
    const url =
      `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/GetRank';
    return this.http.get(url).pipe(
      map((r) => r),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  setRank(payload: any): Observable<any> {
    const url =
      `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/SetRank';
    return this.http.post(url, payload).pipe(
      map((r) => r),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  cueIcons(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/cueIcon';
    return this.http.get(url).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => {
        return x;
      }),
      catchError((x) => {
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of, map } from 'rxjs';
import { ConfigService } from '@cue/admin-core';

@Injectable({
  providedIn: 'root',
})
export class AdminUserValidators {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  createUniqueAdminUserExternalEmailValidator(
    id: string = '00000000-0000-0000-0000-000000000000',
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (control.value == null) {
        return of(null);
      }
      const url =
        this.configService.value.apiURL + '/api/adminusers/uniqueExternalEmail/' + control.value;
      return this.http
        .post(url, { id })
        .pipe(map((response: any) => (response.success ? null : { valueIsUsed: true })));
    };
  }
}

import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';
import { AppNotificationService } from './app-notification.service';

@Injectable({
  providedIn: 'root',
})
export class ImageTemplateService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private appNotificationService: AppNotificationService,
  ) {}

  getDetail(id: string): Observable<any> {
    return this.getAction('/imageTemplate/detail/' + id);
  }

  add(payload: any): Observable<any> {
    return this.postAction('/imageTemplate', payload);
  }

  edit(id: string, payload: any): Observable<any> {
    return this.postAction('/imageTemplate/' + id + '/edit', payload);
  }

  delete(id: string): Observable<any> {
    const url =
      `${this.configService.value.apiURL}` +
      CONSTANTS.api.prefix +
      '/imageTemplate/' +
      id +
      '/delete';
    return this.http.delete(url);
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, payload).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({ success: false, errorCode: x.status, errorDescription: 'Unexpected error.' });
      }),
    );
  }
}

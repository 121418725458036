import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';
import { AppNotificationService } from './app-notification.service';
import { ValidationService } from './validation.service';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root',
})
export class AdminUserService implements ValidationService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private appNotificationService: AppNotificationService,
  ) {}

  loadData(): Observable<any> {
    const payload = {
      role: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  getConAgnts(): Observable<any> {
    const payload = {
      connectionAgent: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  loadDataForGrid(): Observable<any> {
    const payload = {
      role: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  getDetailById(id: number) {
    const filters = {
      take: 1,
      skip: 0,
      filter: {
        filters: [
          {
            field: 'id',
            value: id,
            operator: 'eq',
          },
        ],
        logic: 'and',
      },
    } as DataStateChangeEvent;

    const query = toDataSourceRequestString(filters);
    return this.http
      .post<any[]>(this.configService.value.apiURL + '/api/adminusers/grid?&' + query, {})
      .pipe(
        map(
          (resp: any) =>
            resp.data.map((adminUser) => ({
              ...adminUser,
            }))[0],
        ),
      );
  }

  isUniqueName(email: string, id: number): Observable<any> {
    if (email) {
      const url = this.configService.value.apiURL + '/api/adminUsers/uniqueEmail/';
      const urlF = `${url}${email}`;
      return this.http.post(urlF, { id });
    }
    return of({ success: true });
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, catchError, mergeMap, tap } from 'rxjs/operators';
import { ServersService } from '../services';
import {
  ServersLoadDoneAction,
  ServersLoadFailedAction,
  ServersActionTypes,
  ServersLoadAction,
} from '@cue/admin-shared';

@Injectable()
export class ServersEffects {
  constructor(
    private serversService: ServersService,
    private actions$: Actions,
  ) {}

  effectLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ServersLoadAction>(ServersActionTypes.Load),
      switchMap((action) =>
        this.serversService.actionLoad().pipe(
          mergeMap((action) => {
            return [new ServersLoadDoneAction(action.data)];
          }),
          catchError((err) => {
            return [new ServersLoadFailedAction({ state: { errorCode: err.status } })];
          }),
        ),
      ),
    ),
  );
}

<app-dialog-wrapper
    [title]="'Response'"
    [activeButtonExist]="false"
    (cancelDialog)="cancelDialog.emit()"
    [cancelButtonLabel]="'actions.close' | translate"
>
    <ng-container>
        <kendo-grid class="dialogGrid" [data]="gridData" scrollable="none">
            <kendo-grid-column field="username" [title]="'Username'"> </kendo-grid-column>
            <kendo-grid-column field="success" [title]="'Success'">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.success" class="successColumn">OK</span>
                    <span *ngIf="!dataItem.success" class="errorColumn">Error</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="errorCode" [title]="'Error code'">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="!dataItem.success; else noValue">{{ dataItem.errorCode }}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="errorDescription" [title]="'Error description'">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="!dataItem.success; else noValue">{{
                        dataItem.errorDescription
                    }}</span>
                </ng-template>
            </kendo-grid-column>
            <ng-template #noValue> --- </ng-template>
        </kendo-grid>
        <ng-content></ng-content>
    </ng-container>
</app-dialog-wrapper>

import { TranslateService } from '@ngx-translate/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpContext } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@cue/admin-constants';
import { AppNotificationService } from './app-notification.service';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { ValidationService } from './validation.service';
import { GENERATE_PIN } from '../interceptors';

@Injectable({
  providedIn: 'root',
})
export class SupportService implements ValidationService {
  public entity = CONSTANTS.entity.serviceType;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
    private appNotificationService: AppNotificationService,
  ) {}

  loadDataForGrid(): Observable<any> {
    const payload = {
      serviceUnitType: true,
    };
    return this.postAction(CONSTANTS.api.loadData, { data: payload });
  }

  inactivate(ids: number[]) {
    return this.postAction(this.entity + '/inactivate', ids);
  }

  activate(ids: number[]) {
    return this.postAction(this.entity + '/activate', ids);
  }

  loadDetail(id: number) {
    return this.getAction(this.entity + '/' + id + '/load');
  }

  getDropdownData() {
    return this.getAction(this.entity + '/dropdownData');
  }

  isUniqueName(name: string, id: number): Observable<any> {
    if (name) {
      const url = this.configService.value.apiURL + '/api/serviceType/uniqueName/' + name;
      return this.http.post(url, id ? { id } : {});
    }
    return of({ success: true });
  }

  getServicesGrid(serviceTypeId: number): Observable<any> {
    const filters = {
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'serviceTypeId',
            operator: 'eq',
            value: serviceTypeId,
          },
        ],
      },
    } as DataStateChangeEvent;

    const query = toDataSourceRequestString(filters);
    const url = this.configService.value.apiURL + '/api/service/grid?&' + query;
    return this.http.post(url, {});
  }

  add(payload: any): Observable<any> {
    return this.postAction(this.entity, payload);
  }

  edit(id: string, payload: any) {
    return this.postAction(this.entity + '/' + id + '/edit', payload);
  }

  generatePinCode() {
    return this.http.get<any>(
      this.configService.value.apiURL + '/api' + this.entity + '/generatePin',
      { context: new HttpContext().set(GENERATE_PIN, true) },
    );
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, payload).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => {
        return x;
      }),
      catchError((x) => {
        console.log('err');
        return of({ success: false, errorCode: x.status, errorDescription: 'Unexpected error.' });
      }),
    );
  }
}

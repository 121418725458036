@if (hasFormControl) {
    <ng-container [formGroup]="parentForm">
        @if (isMultiEdit) {
            @if (label) {
                <label class="k-form-field">
                    {{ label | translate }}
                    <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
                </label>
            }
            <kendo-dropdownlist
                [id]="cName"
                [formControlName]="cName"
                [data]="dropdownData"
                [textField]="'name'"
                [valueField]="'value'"
                [valuePrimitive]="true"
            >
            </kendo-dropdownlist>
        } @else {
            @if (label && !labelDown) {
                <div>
                    <label class="k-checkbox-label">
                        {{ label | translate }}
                        <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
                    </label>
                </div>
            }
            <input type="checkbox" [id]="cName" class="k-checkbox" [formControlName]="cName" />
            @if (label && labelDown) {
                <label class="k-checkbox-label" [for]="cName">
                    {{ label | translate }}
                    <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
                </label>
            }
        }
        <ng-template #contentTemplate>
            <ng-content></ng-content>
        </ng-template>
    </ng-container>
} @else {
    @if (isMultiEdit || withDefaultValue) {
        <kendo-dropdownlist
            [id]="cName"
            [value]="cValue"
            [data]="dropdownData"
            [textField]="'name'"
            [valueField]="'value'"
            [valuePrimitive]="true"
            (valueChange)="valueChange.emit($event)"
            [disabled]="isDisabled"
        >
        </kendo-dropdownlist>
    }
    @if (!isMultiEdit && !withDefaultValue) {
        <input
            type="checkbox"
            [id]="cName"
            class="k-checkbox"
            [checked]="cValue"
            (change)="valueChange.emit($any($event.target).checked)"
            [disabled]="isDisabled"
        />
    }
}
